import React from "react"
import { Link, graphql } from "gatsby"
import styles from "./blog-post.module.css"

// Utilities
import kebabCase from "lodash/kebabCase"

import BlogContainer from "../components/blog-container"
import LatestPost from "../components/latest-posts"

import SEO from "../components/seo"

export default ({ data }) => {
  const post = data.markdownRemark
  return (
    <BlogContainer>
      <SEO 
        title={post.frontmatter.title} 
        description={post.frontmatter.subheader}
        url={`https://terencelucasyap.com` + post.fields.slug}
        image={post.frontmatter.image}
      />
      <>
        <img 
          src={post.frontmatter.image}
          alt={post.frontmatter.title}
          className={styles.banner} 
        />
        <div className={styles.content}>
        <ul className={styles.meta}>
            {post.frontmatter.tags.map((item, index) => (
            <li className={styles.category} key={index}>
                <Link to={`/tags/${kebabCase(item)}/`}>
                    {item}
                    { index < post.frontmatter.tags.length - 1 && <span>, </span>}
                </Link>
            </li>
            ))}
            <li className={styles.date}>
                - {post.frontmatter.date}
            </li>
        </ul>
        <h1>{post.frontmatter.title}</h1>
        <h2 className={styles.subheader}>{post.frontmatter.subheader}</h2>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      <h3>Latest Posts</h3>
      <LatestPost />
      </div>
      </>
    </BlogContainer>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(fromNow: true)
        subheader
        tags
        image
      }
      fields {
        slug
      }
      id
    }
  }
`