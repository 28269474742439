import React from "react"
import {useStaticQuery, Link, graphql} from "gatsby"

import containerStyles from "./latest-post.module.css"

export default() => {
    const data = useStaticQuery(graphql `
      query {
        allMarkdownRemark(limit: 3, sort: {fields: frontmatter___date, order: DESC}) {
            edges {
              node {
                frontmatter {
                  title
                  image
                }
                fields {
                  slug
                }
                id
              }
            }
          }
      }
    `)
    return (
        <div className={containerStyles.tilesContainer}>
          {data
              .allMarkdownRemark
              .edges
              .map(({node, index}) => (
                <div className={containerStyles.tiles} key={node.id}>
                    <Link to={node.fields.slug}>
                    <img src={node.frontmatter.image} alt={node.frontmatter.title}/>
                    <span>{node.frontmatter.title}</span>
                </Link>
                </div>
              ))}
        </div>
    )
}